//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "MobCultureSelector",
    props: ["items", "selectedCulture"],
    data() {
        return {
            show: false,
        };
    },
    methods: {
        onItemClick(e) {
            this.show = false;
            this.$emit("change", e);
        },
    },
};
