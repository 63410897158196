//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MapFilterGroupBase from "./MapFilterGroupBase.vue";
export default {
    components: { MapFilterGroupBase },
    name: "HybridSelector",
    props: ["items", "currentListIds", "label"],
    methods: {
        isSelected(arg) {
            return this.currentListIds.indexOf(arg) > -1;
        },
        onItemClick(id) {
            if (!this.isSelected(id)) {
                this.$emit("include", id);
            } else {
                this.$emit("exclude", id);
            }
        },
    },
};
