//
//
//
//
//
//
//
//

import FormBase from "../components/Forms/FormBase.vue";
import ResetPassForm from "../components/Forms/ResetPassForm.vue";
import modal from "../components/modal.vue";
export default {
    components: { modal, FormBase, ResetPassForm },
    name: "LoginPage",
};
