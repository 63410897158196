//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MapFilterGroupBase from "./MapFilterGroupBase.vue";
export default {
    props: ["selectedValue", "label"],
    name: "MobSelector",
    components: { MapFilterGroupBase },
    data() {
        return {
            open: false,
        };
    },
    computed: {
        selected() {
            let a;
            this.propsData.forEach((item) => {
                if (this.selectedValue == item.id) a = item.name;
            });
            return a || "undefined";
        },
    },
    methods: {
        onItemSelect(val) {
            this.open = false;
            this.$emit("select", val);
        },
        onCansel() {
            this.open = false;
            this.$emit("select", null);
        },
    },
};
