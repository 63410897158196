//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex";
import modal from "../components/modal.vue";
export default {
    components: { modal },
    name: "confirmEmail",
    computed: {
        ...mapGetters(["getPostRegEmail"]),
    },
    async mounted() {
        try {
            const payload = {
                token: this.$route.params.token,
            };
            await this.axios.post(
                "/api/auth/user-email-confirm/token",
                payload
            );
        } catch (e) {
            console.warn(e);
        } finally {
            this.process = false;
        }
    },
};
