//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { required, minLength, sameAs } from "vuelidate/lib/validators";
import FormInput from "../Inputs/FormInput.vue";
export default {
    components: { FormInput },
    name: "passwordChangeForm",
    data() {
        return {
            showError: false,
            process: false,

            password: "",
            passwordConfirm: "",

            token: null,
        };
    },
    validations() {
        return {
            password: { required, minLength: minLength(8) },
            passwordConfirm: {
                required,
                sameAsPassword: sameAs("password"),
            },
        };
    },
    async mounted() {
        this.process = true;
        try {
            const {
                data: {
                    data: { token },
                },
            } = await this.axios.post("/api/auth/password-reset/token", {
                token: this.$route.params.token,
            });
            this.token = token;
        } catch (e) {
            alert("Wrong!");
            console.log(e);
        } finally {
            this.process = false;
        }
    },
    methods: {
        async onsubmit() {
            this.showError = true;
            if (!this.process && !this.$v.$invalid) {
                try {
                    this.process = true;
                    const payload = {
                        token: this.token,
                        password: this.password,
                    };
                    await this.axios.post("/api/auth/password-change", payload);
                    this.$store.dispatch("setAccessToken", null);
                    this.$store.dispatch("setUser", null);
                    this.$router.push("/login");
                } catch (e) {
                    console.warn(e);
                } finally {
                    this.process = false;
                }
            }
        },
    },
};
