//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { required, email, minLength } from "vuelidate/lib/validators";
import FormInput from "../Inputs/FormInput.vue";
import { mapActions } from "vuex";
export default {
    components: { FormInput },
    name: "LoginForm",
    data() {
        return {
            showError: false,
            process: false,
            email: "",
            password: "",
        };
    },
    validations() {
        return {
            email: { required, email },
            password: { required, minLength: minLength(8) },
        };
    },
    methods: {
        ...mapActions(["setAccessToken", "setUser"]),
        async onsubmit() {
            this.showError = true;
            if (!this.process && !this.$v.$invalid) {
                try {
                    this.process = true;
                    const payload = {
                        email: this.email,
                        password: this.password,
                    };
                    const {
                        data: {
                            data: { access_token, user },
                        },
                    } = await this.axios.post("/api/auth/login", payload);
                    if (user.status == 2) {
                        this.setAccessToken(access_token);
                        this.setUser(user);
                        setTimeout(() => {
                            this.$router.push("/map");
                        }, 200);
                    } else {
                        this.$store.dispatch("setPostRegEmail", user.email);
                        this.$router.push("/registersuccess");
                    }
                } catch (e) {
                    console.warn(e);
                    const code = e.response.data.data.errors?.code;
                    if (code == 207) {
                        alert("Неправильний пароль");
                    } else if (code == 126) {
                        alert("Користувач не існує");
                    }
                } finally {
                    this.process = false;
                }
            }
        },
    },
};
