//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex";
import modal from "../components/modal.vue";
export default {
    components: { modal },
    name: "resetSendSuccess",
    data() {
        return {
            email: "",
        };
    },
    computed: {
        ...mapGetters(["getPostRegEmail"]),
    },
    mounted() {
        if (this.getPostRegEmail) {
            this.email = this.getPostRegEmail;
            this.$store.dispatch("setPostRegEmail", null);
        } else {
            this.$router.push("/");
        }
    },
};
