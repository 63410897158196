//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CompanyTable from "./CompanyTable.vue";
export default {
    components: { CompanyTable },
    props: ["rightPopupData"],
    name: "RightPopup",
    computed: {},
};
