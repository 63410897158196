import { TheMask } from "vue-the-mask";
export default {
    components: {
        TheMask
    },
    props: [
        "mask",
        "placeholder",
        "itype",
        "errors",
        "propname",
        "validate",
        "showError",
        "wrpstyle",
        "mode",
        "prevalue",
        "handler",
        "title",
        "label",
        'value',
    ],
    data() {
        return {
            locValue: "",
            labelWidth: 0,
            isFocus: false
        };
    },
    watch: {
        value(newValue) {
            this.locValue = newValue
        }
    },
    methods: {
        onKeyUp() {
            if (this.propname != null) {
                // this.$emit("change", {
                // 	name: this.propname,
                // 	locValue: this.locValue
                // });
                this.$emit("input", this.locValue);
            }
        },
        onBtnClc() {
            if (this.mode == "button") {
                this.$emit("submit");
            }
        },
        onFocus() {
            this.isFocus = true;
        },
        onBlur() {
            this.isFocus = false;
        },
        handlerFunc() {
            if (this.handler == "phone")
                this.locValue = this.locValue.replace(/[^0-9.]/g, "");
            else if (this.handler == "volume") {
                this.locValue = this.locValue.replace(/[^0-9.]/g, "");
                if (this.locValue.match(/[.]/g)?.length > 1) {
                    this.locValue = this.locValue.substring(0, this.locValue.length - 1)
                }
            }
        }
    },
    computed: {
        isMaskedInput() {
            if (this.mask !== undefined && this.mask !== null && this.mask !== "")
                return true;
            else return false;
        },
        isError() {
            if (this.validate) {
                if (this.validate.$invalid && this.showError) {
                    if (this.validate.required === false) {
                        return this.$t("fieldErrors.required");
                    }
                    if (this.validate.minLength === false) {
                        return this.$t("fieldErrors.minLength", {
                            length: this.validate.$params.minLength.min
                        });
                    }
                    if (this.validate.maxLength === false) {
                        return this.$t("fieldErrors.maxLength", {
                            length: this.validate.$params.maxLength.max
                        });
                    }
                    if (this.validate.email === false) {
                        return this.$t("fieldErrors.email");
                    }
                    if (this.validate.sameAsPassword === false) {
                        return this.$t("fieldErrors.sameAsPassword");
                    }
                    return "some error";
                } else return null;
            } else return null;
        },
        btnClass() {
            if (this.mode == "button") {
                if (this.locValue.trim() != "") {
                    return "active";
                }
            } else return "";
        }
    },
    mounted() {
        this.locValue = this.value
    }
};
