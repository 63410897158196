import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

export default new VueI18n({
    // locale: process.env.VUE_APP_I18N_LOCALE || 'uk',
    locale: "uk",
    // fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    fallbackLocale: "en",
    messages: {
        uk: {
            fieldErrors: {
                email: 'Введiть email',
                required: "Обов'язкове поле",
                minLength: "Мінімальна кількість символів: {length}",
                maxLength: "Максимальна кількість символів: {length}",
                selectDate: "Вкажіть дату",
                selectRadio: "Оберіть варіант",
                selectTime: "Вкажіть час",
                maxAuctionRange: "На жаль ваша ставка перевищує максимальний крок. Максимальна ціна {value} Спробуйте зменшити ціну.",
                minAuctionRange: "На жаль ваша ставка занадто мала для цього аукціону. Мінімальна ціна {value}. Спробуйте збільшити ціну.",
                maxAucAmount: 'Ваша ставка перевищує об’єм сировини доступний на аукціоні',
                sameAsPassword: `Поля не співпадають`,
                // minAucAmount: 'Ваша ставка перевищує об’єм сировини доступний на аукціоні',
            },
        },
        en: {},
    },
});
