//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MapFilterGroupBase from "./MapFilterGroupBase.vue";
export default {
    props: ["selectedValue", "label"],
    components: { MapFilterGroupBase },
    name: "MapFilterGroupListMulty",
    methods: {
        onItemSelect(val) {
            const a = this.selectedValue || [];
            const ind = a.indexOf(val);
            if (ind == -1) {
                a.push(val);
            } else {
                a.splice(ind, 1);
            }
            this.$emit("select", a);
        },
        onCansel() {
            this.$emit("select", null);
        },
        isActive(arr, val) {
            if (arr != null) {
                let i = arr.indexOf(val);
                return i != -1;
            } else return false;
        },
    },
};
