//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MapFilterGroupBase from "./MapFilterGroupBase.vue";
export default {
    props: ["selectedValue"],
    components: { MapFilterGroupBase },
    name: "MapFilterGroupIcoList",
    methods: {
        onItemSelect(val) {
            this.$emit("select", val);
        },
        onCanseld() {
            this.$emit("select", false);
        },
    },
};
