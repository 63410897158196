//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex";
export default {
    metaInfo() {
        return {
            title: this.getMainMeta,
        };
    },
    name: "MainPage",
    data() {
        return {
            width: 0,
        };
    },
    computed: {
        ...mapGetters(["getLogo", "getMainMeta", "isLogin"]),
        banner() {
            return this.width > 991
                ? require(`@/assets/hero-section.jpg`)
                : require(`@/assets/hero-section-mob.jpg`);
        },
    },
    mounted() {
        this.width = window.innerWidth;
        window.addEventListener("resize", () => {
            this.width = window.innerWidth;
        });
    },
    methods: {
        onEnter() {
            if(this.isLogin){
                this.$router.push('/map')
            }else{
                this.$router.push('/login')
            }
        },
    },
    destroyed() {
        window.removeEventListener("resize", () => {
            this.width = window.innerWidth;
        });
    },
};
