import { render, staticRenderFns } from "./MobSelector.vue?vue&type=template&id=24cea4ff&scoped=true&"
import script from "./MobSelector.vue?vue&type=script&lang=js&"
export * from "./MobSelector.vue?vue&type=script&lang=js&"
import style0 from "./MobSelector.vue?vue&type=style&index=0&id=24cea4ff&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24cea4ff",
  null
  
)

export default component.exports