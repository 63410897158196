const MEDIA_PATH_PREFIX = "/storage/app/public/media";
const FILE_PATH_PREFIX = "/storage/app/public/files";
import Vue from "vue";

let utils = {
	props: ["propsData"],
	computed: {
		locale() {
			return this.$i18n.locale == "" ? "uk" : this.$i18n.locale;
		},
		qLocale() {
			return {
				locale: this.locale == "uk" ? null : this.locale,
			};
		},
		paddingStyle() {
			const obj = {};
			if (this.propsData && this.propsData.bottom_separator != null)
				obj.paddingBottom = `${this.propsData.bottom_separator}px`;
			if (this.propsData && this.propsData.top_separator != null)
				obj.paddingTop = `${this.propsData.top_separator}px`;
			return obj;
		},
		// topSeparator() {
		// 	return this.d.top_separator ? this.d.top_separator : null
		// },
		// bottomSeparator() {
		// 	return this.d.bottom_separator ? this.d.bottom_separator : null
		// },
	},
	methods: {
		getBtnClass(s) {
			switch (s) {
				case "type_1":
					return "fill";
				case "type_2":
					return "stroke";
				case "type_3":
					return "simple";
				default:
					return s;
			}
		},
		isExternalHref(to) {
			if (typeof to == "string") {
				if (this.isExtUrl(to)) return true;
				else return false;
			} else if (typeof to == "object") {
				return false;
			} else {
				return null;
			}
		},
		assignHref(to) {
			if (this.isExternalHref(to)) {
				window.location.assign(to);
			} else {
				this.$router.push(to);
			}
		},
		path(s) {
			if (s) {
				if (process.env.NODE_ENV == "development")
					return `${process.env.VUE_APP_URL}${MEDIA_PATH_PREFIX}${s}`;
				else return MEDIA_PATH_PREFIX + s;
			} else return null;
		},
		pathFile(s) {
			if (s) {
				if (process.env.NODE_ENV == "development")
					return `${process.env.VUE_APP_URL}${FILE_PATH_PREFIX}${s}`;
				else return FILE_PATH_PREFIX + s;
			} else return null;
		},
		isExtUrl(s) {
			if (s.includes("http")) return true;
			else if (s.includes("@")) return true;
			else return false;
		},
		linkClick(link, blank) {
			if (this.isExtUrl(link)) {
				if (link.includes("@")) {
					location.href = link;
				} else {
					if (blank) {
						window.open(link, "_blank");
					} else {
						window.location.assign(link);
					}
				}
			} else {
				this.$router.push(link);
			}
		},
		refreshSlider(refname) {
			this.$refs[refname].resize();
		},
		solveStatus(status) {
			if (status == 404) {
				this.$router.push({
					name: "Page404",
				});
			}
		},
		parceYtId(s) {
			return s.replace(/(.*?)watch\?v=/, "");
		},

		// Google Tag Manager
		TGMPush(arg) {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				event: "page_view",
				CityName: arg.city || "",
				pageTitle: arg.title || "",
				pagePath: this.$route.fullPath,
				originalLocation: window.location.origin,
			});
		},
		// Input methods
		onFieldChange(e) {
			if (typeof e.name == "object") {
				if (e.name.array) {
					this[e.name.array][e.name.index][e.name.name] = e.value;
				}
			} else {
				this[e.name] = e.value;
			}
		},
		onPhoneChange(e) {
			this[e.name] = e.value;
			if (e.lengthname) {
				this[e.lengthname] = e.length;
			} else {
				this.phoneLength = e.length;
			}
		},
		onRadioChange(e) {
			if (typeof e.name == "object") {
				if (e.name.array) {
					this[e.name.array][e.name.index][e.name.name] = e.value;
					if (e.extra) {
						this[e.name.array][e.name.index][
							`${e.name.name}Extra`
						] = e.value;
					} else {
						this[e.name.array][e.name.index][e.name.name] = e.value;
					}
				}
			} else {
				if (e.extra) {
					this[`${e.name}Extra`] = e.value;
				} else {
					this[e.name] = e.value;
					if (!e.haveExtra) {
						this[`${e.name}Extra`] = "";
					}
				}
			}
		},
		handleError(e) {
			if (e.response.status == 404) {
				this.$router.replace({
					name: "error",
					params: {
						...this.qLocale,
					},
				});
			} else {
				console.warn(e);
			}
		},
		setMeta(pageData) {
			if (pageData === true) {
				document.title =
					this.pageData.title || this.pageData.meta_title || "";
				document.description =
					this.pageData.description ||
					this.pageData.meta_description ||
					"";
			} else {
				document.title =
					this.translate.name ||
					this.translate.title ||
					this.model.name ||
					this.model.title ||
					"";
				document.description =
					this.translate.meta_description ||
					this.model.meta_description ||
					"";
			}
		},
	},
};

export { utils };

export let rtParams = {
	ruLocaleParam() {
		let p = {};
		for (const [key, value] of Object.entries(this.$route.params)) {
			p[key] = value;
		}
		p.locale = "ru";
		return p;
	},
	uaLocaleParam() {
		let p = {};
		for (const [key, value] of Object.entries(this.$route.params)) {
			p[key] = value;
		}
		p.locale = "uk";
		return p;
	},
	enLocaleParam() {
		let p = {};
		for (const [key, value] of Object.entries(this.$route.params)) {
			p[key] = value;
		}
		p.locale = null;
		return p;
	},
};

let adapter = {
	post: (url, data, context) => {
		return new Promise((resolve, reject) => {
			Vue.axios
				.post(url, data)
				.then((response) => resolve(response.data.data))
				.catch((e) => {
					if (e.response.status == 302) {
						context.$router.push({
							name: "home",
							params: { ...Vue.qLocale },
						});
					} else if (e.response.status != 404) {
						alert(`Что то пошло не так: ${e}`);
					}
					reject(e);
				});
		});
	},
};
export { adapter };
