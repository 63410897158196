//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MapFilterGroupBase from "./MapFilterGroupBase.vue";
export default {
    props: ["selectedValue"],
    components: { MapFilterGroupBase },
    name: "MapFilterGroupStack",
    methods: {
        onItemClick(val) {
            if (this.selectedValue != val) {
                this.$emit("select", val);
            }
        },
    },
};
