//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { required, email } from "vuelidate/lib/validators";
import FormInput from "../Inputs/FormInput.vue";
export default {
    components: { FormInput },
    name: "LoginForm",
    data() {
        return {
            showError: false,
            process: false,
            email: "",
        };
    },
    validations() {
        return {
            email: { required, email },
        };
    },

    methods: {
        async onsubmit() {
            this.showError = true;
            if (!this.process && !this.$v.$invalid) {
                try {
                    this.process = true;
                    const response = await this.axios.post(
                        "/api/auth/password-reset",
                        {
                            email: this.email,
                        }
                    );
                    console.log(response);
                    this.$store.dispatch("setPostRegEmail", this.email);
                    this.$router.push("/resetsendsuccess");
                } catch (e) {
                    alert("Wrong!");
                    console.warn(e);
                } finally {
                    this.process = false;
                }
            }
        },
    },
};
