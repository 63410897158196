//
//
//
//
//
//
//
//

import FormBase from "../components/Forms/FormBase.vue";
import PasswordChangeForm from "../components/Forms/passwordChangeForm.vue";
import modal from "../components/modal.vue";
export default {
    components: { modal, FormBase, PasswordChangeForm },
    name: "passwordChangePage",
};
