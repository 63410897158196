//
//
//
//
//
//
//
//

import FormBase from "../components/Forms/FormBase.vue";
import LoginForm from "../components/Forms/LoginForm.vue";
import modal from "../components/modal.vue";
export default {
    components: { modal, LoginForm, FormBase },
    name: "LoginPage",
};
