//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import defaultinput from "./DefaultInputMixin";
export default {
    props: ["styleClass", "pattern", "tipLabel", "successShow"],
    name: "FormInput",
    mixins: [defaultinput],
    data() {
        return {
            defaultSlotExist: false,
        };
    },
    watch: {
        // value(newValue, oldValue) {
        //     console.log('update', newValue)
        // },
    },
    computed: {
        icoSuccess() {
            return this.validate?.$invalid || this.locValue;
        },
    },
    mounted() {
        this.defaultSlotExist = this.$slots.default ? true : false;
    },
};
