//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CompareBtn from "./common/CompareBtn.vue";
export default {
    components: { CompareBtn },
    props: ["item", "culture"],
    name: "CompanyTable",
    data() {
        return {
            currentTab: 0,
            details: false,
        };
    },
    computed: {
        name() {
            return this.data;
        },
    },
    methods: {
        getId(str) {
            if (str && str.split("/")) return str.split("/")[3];
            else return false;
        },
        getCurrentTab(item) {
            switch (this.currentTab) {
                case 0:
                    return item.tab_statistic;
                case 1:
                    return item.tab_params;
                default:
                    return null;
            }
        },
    },
};
