export default {
    UKR283: 44,
    UKR284: 32,
    UKR285: 43,
    UKR286: 35,
    UKR288: 42,
    UKR289: 30,
    UKR290: 40,
    UKR291: 22,
    UKR292: 37,
    UKR293: 28,
    UKR318: 24,
    UKR319: 41,
    UKR320: 19,
    UKR321: 1,
    UKR322: 33,
    UKR323: 2,
    UKR324: 27,
    UKR325: 36,
    UKR326: 25,
    UKR327: 26,
    UKR328: 38,
    UKR329: 31,
    UKR330: 34,
    UKR331: 29,
    UKR4826: 1, // киев сити
    UKR4827: 39,
    UKR5482: 44, // севастополь
}
export const stateLabels = {
    "0": {
        name: "Одеська",
        x: 413.9182,
        y: 537.31786,
        parent_id: "UKR322"
    },
    "2": {
        name: "АР Крим",
        x: 673.9,
        y: 600.7,
        parent_id: "UKR283"
    },
    "3": {
        name: "Миколаївська",
        x: "550",
        y: "425",
        parent_id: "UKR284"
    },
    "4": {
        name: "Чернігівська",
        x: "536.8",
        y: 93.8,
        parent_id: "UKR285"
    },
    "5": {
        name: "Рівненська",
        x: 254.1,
        y: 93.9,
        parent_id: "UKR286"
    },
    "6": {
        name: "Чернівецька",
        x: 210.1,
        y: 359.7,
        parent_id: "UKR288"
    },
    "7": {
        name: "Івано-Франківська",
        x: 142.9,
        y: 324.8,
        parent_id: "UKR289"
    },
    "8": {
        name: "Хмельницька",
        x: 268.9,
        y: 254,
        parent_id: "UKR290"
    },
    "9": {
        name: "Львівська",
        x: 86.5,
        y: 243.3,
        parent_id: "UKR291"
    },
    "10": {
        name: "Тернопільська",
        x: 188,
        y: 263.9,
        parent_id: "UKR292"
    },
    "11": {
        name: "Закарпатська",
        x: "60",
        y: "350",
        parent_id: "UKR293"
    },
    "12": {
        name: "Волинська",
        x: 144.1,
        y: 100,
        parent_id: "UKR318"
    },
    "13": {
        name: "Черкаська",
        x: 537,
        y: 261.6,
        parent_id: "UKR319"
    },
    "14": {
        name: "Кіровоградська",
        x: 567.9,
        y: 343.2,
        parent_id: "UKR320"
    },
    "15": {
        name: "Київська",
        x: "460",
        y: "220",
        parent_id: "UKR321"
    },
    "17": {
        name: "Вінницька",
        x: 364.1,
        y: 310.1,
        parent_id: "UKR323"
    },
    "18": {
        name: "Житомирська",
        x: 346,
        y: 145.8,
        parent_id: "UKR324"
    },
    "19": {
        name: "Сумська",
        x: 647.5,
        y: 129.7,
        parent_id: "UKR325"
    },
    "20": {
        name: "Дніпропетровська",
        x: 712.4,
        y: "340",
        parent_id: "UKR326"
    },
    "21": {
        name: "Донецька",
        x: 864.2,
        y: 380.4,
        parent_id: "UKR327"
    },
    "22": {
        name: "Харківська",
        x: 800.4,
        y: 251,
        parent_id: "UKR328"
    },
    "23": {
        name: "Луганська",
        x: 929.5,
        y: 275.5,
        parent_id: "UKR329"
    },
    "24": {
        name: "Полтавська",
        x: 644.4,
        y: 240.7,
        parent_id: "UKR330"
    },
    "25": {
        name: "Запорізька",
        x: 754.7,
        y: 439.6,
        parent_id: "UKR331"
    },
    "27": {
        name: "Херсонська",
        x: 647.9,
        y: 477.8,
        parent_id: "UKR4827"
    }
}

export function areaLabels() {
    const obj = {};
    for (const key in areaLabelsObj) {
        if (Object.hasOwnProperty.call(areaLabelsObj, key)) {
            const element = areaLabelsObj[key];
            obj[key] = element;
            // obj[key].scale = "yes";
            obj[key].size = 4;
            obj[key].font = "Inter";
            obj[key].fontWeight = "200";
        }
    }
    return obj;
}
const areaLabelsObj = {
    "0": {
        name: "Ізмаїльський",
        x: 380.46182,
        y: 582.48698,
        parent_id: "UKR322",
    },
    "1": {
        name: "Болградський",
        x: 374.59449,
        y: 544.10483,
        parent_id: "UKR322"
    },
    "2": {
        name: "Білгород-Дністровський",
        x: 433.26784,
        y: 537.25961,
        parent_id: "UKR322"
    },
    "3": {
        name: "Одеський",
        x: 467.86067,
        y: 497.16725,
        parent_id: "UKR322"
    },
    "4": {
        name: "Роздільнянський",
        x: 425.93367,
        y: 459.76189,
        parent_id: "UKR322"
    },
    "5": {
        name: "Березівський",
        x: 467.00501,
        y: 445.16341,
        parent_id: "UKR322"
    },
    "6": {
        name: "Подільський",
        x: 420.38065,
        y: 395.39583,
        parent_id: "UKR322"
    },
    "7": {
        name: "Миколаївський",
        x: 534.9803,
        y: 461.92774,
        parent_id: "UKR284"
    },
    "8": {
        name: "Баштанський",
        x: 580.61944,
        y: 423.84267,
        parent_id: "UKR284"
    },
    "9": {
        name: "Вознесенський",
        x: 510.11484,
        y: 412.82632,
        parent_id: "UKR284"
    },
    "10": {
        name: "Первомайський",
        x: 479.89858,
        y: 379.14779,
        parent_id: "UKR284"
    },
    "11": {
        name: "Бериславський",
        x: 628.05714,
        y: 444.28652,
        parent_id: "UKR4827"
    },
    "12": {
        name: "Каховський",
        x: 638.72855,
        y: 485.35528,
        parent_id: "UKR4827"
    },
    "13": {
        name: "Херсонський",
        x: 586.01825,
        y: 485.67866,
        parent_id: "UKR4827"
    },
    "14": {
        name: "Скадовський",
        x: 572.43646,
        y: 516.39938,
        parent_id: "UKR4827"
    },
    "15": {
        name: "Генічеський",
        x: 686.91158,
        y: 506.37473,
        parent_id: "UKR4827"
    },
    "16": {
        name: "Перекопський",
        x: 637.88889,
        y: 553.26437,
        parent_id: "UKR283"
    },
    "17": {
        name: "Джанкойський",
        x: 681.69841,
        y: 563.88486,
        parent_id: "UKR283"
    },
    "18": {
        name: "Курманський",
        x: 660.12554,
        y: 581.80694,
        parent_id: "UKR283"
    },
    "19": {
        name: "Євпаторійський",
        x: 622.29004,
        y: 595.74633,
        parent_id: "UKR283"
    },
    "20": {
        name: "Сімферопольський",
        x: 658.1342,
        y: 621.30189,
        parent_id: "UKR283"
    },
    "21": {
        name: "Бахчисарайський",
        x: 652.82395,
        y: 647.18933,
        parent_id: "UKR283"
    },
    "22": {
        name: "Ялтинський",
        x: 675.72439,
        y: 654.15903,
        parent_id: "UKR283"
    },
    "23": {
        name: "Білогірський",
        x: 689.99567,
        y: 613.33652,
        parent_id: "UKR283"
    },
    "24": {
        name: "Запорізький",
        x: 734.68845,
        y: 388.6671,
        parent_id: "UKR331"
    },
    "25": {
        name: "Василівський",
        x: 712.58893,
        y: 434.51536,
        parent_id: "UKR331"
    },
    "26": {
        name: "Пологівський",
        x: 786.80373,
        y: 419.34256,
        parent_id: "UKR331"
    },
    "27": {
        name: "Бердянський",
        x: 795.70951,
        y: 463.87144,
        parent_id: "UKR331"
    },
    "28": {
        name: "Мелітопольський",
        x: 728.42142,
        y: 478.05471,
        parent_id: "UKR331"
    },
    "29": {
        name: "Маріупольський",
        x: 844.45822,
        y: 445.69303,
        parent_id: "UKR327"
    },
    "30": {
        name: "Волноваський",
        x: 836.47084,
        y: 405.75609,
        parent_id: "UKR327"
    },
    "31": {
        name: "Кальміуський",
        x: 891.12139,
        y: 420.04931,
        parent_id: "UKR327"
    },
    "32": {
        name: "Донецький",
        x: 891.96217,
        y: 384.73664,
        parent_id: "UKR327"
    },
    "33": {
        name: "Горлівський",
        x: 905.41461,
        y: 367.5007,
        parent_id: "UKR327"
    },
    "34": {
        name: "Покровський",
        x: 841.5155,
        y: 360.35409,
        parent_id: "UKR327"
    },
    "35": {
        name: "Краматорський",
        x: 843.19706,
        y: 317.47443,
        parent_id: "UKR327"
    },
    "36": {
        name: "Бахмутський",
        x: 882.71361,
        y: 329.6657,
        parent_id: "UKR327"
    },
    "37": {
        name: "Сватівський",
        x: 899.77357,
        y: 246.2302,
        parent_id: "UKR329"
    },
    "38": {
        name: "Старобільський",
        x: 959.16697,
        y: 263.84341,
        parent_id: "UKR329"
    },
    "39": {
        name: "Сєвєродонецький",
        x: 902.64084,
        y: 295.79297,
        parent_id: "UKR329"
    },
    "40": {
        name: "Щастинський",
        x: 948.10751,
        y: 304.80438,
        parent_id: "UKR329"
    },
    "41": {
        name: "Алчевський",
        x: 919.02522,
        y: 337.57315,
        parent_id: "UKR329"
    },
    "42": {
        name: "Луганський",
        x: 953.43243,
        y: 343.7173,
        parent_id: "UKR329"
    },
    "43": {
        name: "Ровеньківський",
        x: 944.83063,
        y: 376.48607,
        parent_id: "UKR329"
    },
    "44": {
        name: "Довжанський",
        x: 971.86487,
        y: 370.34193,
        parent_id: "UKR329"
    },
    "45": {
        name: "Криворізький",
        x: 631.09812,
        y: 390.42202,
        parent_id: "UKR326"
    },
    "46": {
        name: "Нікопольський",
        x: 683.31746,
        y: 401.74669,
        parent_id: "UKR326"
    },
    "47": {
        name: "Кам’янський",
        x: 660.35354,
        y: 340.09013,
        parent_id: "UKR326"
    },
    "48": {
        name: "Дніпровський",
        x: 706.59596,
        y: 351.10023,
        parent_id: "UKR326"
    },
    "49": {
        name: "Новомосковський",
        x: 725.78499,
        y: 305.48695,
        parent_id: "UKR326"
    },
    "50": {
        name: "Павлоградський",
        x: 765.10678,
        y: 327.82173,
        parent_id: "UKR326"
    },
    "51": {
        name: "Синельниківський",
        x: 772.65657,
        y: 361.16661,
        parent_id: "UKR326"
    },
    "52": {
        name: "Харківський",
        x: 778.43468,
        y: 212.69437,
        parent_id: "UKR328"
    },
    "53": {
        name: "Богодухівський",
        x: 733.73117,
        y: 200.12151,
        parent_id: "UKR328"
    },
    "54": {
        name: "Чугуївський",
        x: 808.1206,
        y: 224.56874,
        parent_id: "UKR328"
    },
    "55": {
        name: "Куп’янський",
        x: 852.8241,
        y: 230.15668,
        parent_id: "UKR328"
    },
    "56": {
        name: "Ізюмський",
        x: 830.12311,
        y: 272.41546,
        parent_id: "UKR328"
    },
    "57": {
        name: "Лозівський",
        x: 790.65829,
        y: 295.11646,
        parent_id: "UKR328"
    },
    "58": {
        name: "Красноградський",
        x: 746.65328,
        y: 276.25716,
        parent_id: "UKR328"
    },
    "59": {
        name: "Голованівський",
        x: 454.64069,
        y: 352.14382,
        parent_id: "UKR320"
    },
    "60": {
        name: "Новоукраїнський",
        x: 513.56133,
        y: 341.0962,
        parent_id: "UKR320"
    },
    "61": {
        name: "Кропивницький",
        x: 574.49062,
        y: 357.16546,
        parent_id: "UKR320"
    },
    "62": {
        name: "Олександрійський",
        x: 614.329,
        y: 319.67051,
        parent_id: "UKR320"
    },
    "63": {
        name: "Полтавський",
        x: 690.98392,
        y: 246.55007,
        parent_id: "UKR330"
    },
    "64": {
        name: "Кременчуцький",
        x: 622.59204,
        y: 267.13403,
        parent_id: "UKR330"
    },
    "65": {
        name: "Лубенський",
        x: 591.0521,
        y: 204.71815,
        parent_id: "UKR330"
    },
    "66": {
        name: "Миргородський",
        x: 640.52001,
        y: 191.10617,
        parent_id: "UKR330"
    },
    "67": {
        name: "Шосткинський",
        x: 642.03705,
        y: 45.18321,
        parent_id: "UKR325"
    },
    "68": {
        name: "Конотопський",
        x: 635.60186,
        y: 94.94863,
        parent_id: "UKR325"
    },
    "69": {
        name: "Сумський",
        x: 697.37962,
        y: 130.98565,
        parent_id: "UKR325"
    },
    "70": {
        name: "Роменський",
        x: 637.31791,
        y: 146.43009,
        parent_id: "UKR325"
    },
    "71": {
        name: "Охтирський",
        x: 711.53703,
        y: 174.31589,
        parent_id: "UKR325"
    },
    "72": {
        name: "Уманський",
        x: 440.51035,
        y: 300.37409,
        parent_id: "UKR319"
    },
    "73": {
        name: "Звенигородський",
        x: 492.29579,
        y: 289.48925,
        parent_id: "UKR319"
    },
    "74": {
        name: "Черкаський",
        x: 539.79326,
        y: 270.68816,
        parent_id: "UKR319"
    },
    "75": {
        name: "Золотоніський",
        x: 554.96607,
        y: 236.05459,
        parent_id: "UKR319"
    },
    "76": {
        name: "Прилуцький",
        x: 575.82774,
        y: 152.59505,
        parent_id: "UKR285"
    },
    "77": {
        name: "Ніжинський",
        x: 544.39559,
        y: 113.40188,
        parent_id: "UKR285"
    },
    "78": {
        name: "Чернігівський",
        x: 501.3219,
        y: 73.04455,
        parent_id: "UKR285"
    },
    "79": {
        name: "Корюківський",
        x: 559.91764,
        y: 56.35834,
        parent_id: "UKR285"
    },
    "80": {
        name: "Новгород-Сіверський",
        x: 595.2303,
        y: 23.76204,
        parent_id: "UKR285"
    },
    "81": {
        name: "Вишгородський",
        x: 435.20088,
        y: 114.51741,
        parent_id: "UKR321"
    },
    "82": {
        name: "Бучанський",
        x: 428.26769,
        y: 161.74971,
        parent_id: "UKR321"
    },
    "83": {
        name: "Фастівський",
        x: 436.50085,
        y: 192.51571,
        parent_id: "UKR321"
    },
    "84": {
        name: "Броварський",
        x: 516.23245,
        y: 172.14949,
        parent_id: "UKR321"
    },
    "85": {
        name: "Бориспільський",
        x: 512.76586,
        y: 203.34881,
        parent_id: "UKR321"
    },
    "86": {
        name: "Обухівський",
        x: 485.89977,
        y: 222.41506,
        parent_id: "UKR321"
    },
    "87": {
        name: "Білоцерківський",
        x: 442.13406,
        y: 244.9479,
        parent_id: "UKR321"
    },
    "88": {
        name: "Коростенський",
        x: 353.45104,
        y: 105.44781,
        parent_id: "UKR324"
    },
    "89": {
        name: "Новоград-Волинський",
        x: 307.01639,
        y: 146.02935,
        parent_id: "UKR324"
    },
    "90": {
        name: "Житомирський",
        x: 363.98663,
        y: 179.19695,
        parent_id: "UKR324"
    },
    "91": {
        name: "Бердичівський",
        x: 368.66911,
        y: 213.53518,
        parent_id: "UKR324"
    },
    "92": {
        name: "Хмільницький",
        x: 345.22201,
        y: 243.70499,
        parent_id: "UKR323"
    },
    "93": {
        name: "Вінницький",
        x: 371.10934,
        y: 279.47038,
        parent_id: "UKR323"
    },
    "94": {
        name: "Гайсинський",
        x: 410.28096,
        y: 329.88255,
        parent_id: "UKR323"
    },
    "95": {
        name: "Тульчинський",
        x: 362.59377,
        y: 336.69501,
        parent_id: "UKR323"
    },
    "96": {
        name: "Могилів-Подільський",
        x: 315.58783,
        y: 329.88255,
        parent_id: "UKR323"
    },
    "97": {
        name: "Жмеринський",
        x: 318.31281,
        y: 297.52339,
        parent_id: "UKR323"
    },
    "98": {
        name: "Шепетівський",
        x: 264.0451,
        y: 195.96292,
        parent_id: "UKR290"
    },
    "99": {
        name: "Хмельницький",
        x: 268.75346,
        y: 254.03264,
        parent_id: "UKR290"
    },
    "100": {
        name: "Кам’янець-Подільський",
        x: 259.72911,
        y: 312.49472,
        parent_id: "UKR290"
    },
    "101": {
        name: "Вараський",
        x: 208.61205,
        y: 69.85037,
        parent_id: "UKR286"
    },
    "102": {
        name: "Сарненський",
        x: 264.38795,
        y: 85.78634,
        parent_id: "UKR286"
    },
    "103": {
        name: "Рівненський",
        x: 240.84617,
        y: 154.96295,
        parent_id: "UKR286"
    },
    "104": {
        name: "Дубенський",
        x: 191.22735,
        y: 174.88291,
        parent_id: "UKR286"
    },
    "105": {
        name: "Ковельський",
        x: 119.83948,
        y: 91.45583,
        parent_id: "UKR318"
    },
    "106": {
        name: "Камінь-Каширський",
        x: 164.53437,
        y: 58.72155,
        parent_id: "UKR318"
    },
    "107": {
        name: "Луцький",
        x: 180.272,
        y: 135.20645,
        parent_id: "UKR318"
    },
    "108": {
        name: "Володимир-Волинський",
        x: 128.02305,
        y: 146.2228,
        parent_id: "UKR318"
    },
    "109": {
        name: "Червоноградський",
        x: 122.1514,
        y: 183.04661,
        parent_id: "UKR291"
    },
    "110": {
        name: "Золочівський",
        x: 155.23277,
        y: 212.57085,
        parent_id: "UKR291"
    },
    "111": {
        name: "Яворівський",
        x: 68.08293,
        y: 215.06084,
        parent_id: "UKR291"
    },
    "112": {
        name: "Львівський",
        x: 112.90285,
        y: 231.42367,
        parent_id: "UKR291"
    },
    "113": {
        name: "Самбірський",
        x: 49.58582,
        y: 249.56507,
        parent_id: "UKR291"
    },
    "114": {
        name: "Дрогобицький",
        x: 71.99578,
        y: 267.35075,
        parent_id: "UKR291"
    },
    "115": {
        name: "Стрийський",
        x: 102.58715,
        y: 278.02216,
        parent_id: "UKR291"
    },
    "116": {
        name: "Кременецький",
        x: 205.52205,
        y: 211.46576,
        parent_id: "UKR292"
    },
    "117": {
        name: "Тернопільський",
        x: 181.59222,
        y: 254.15139,
        parent_id: "UKR292"
    },
    "118": {
        name: "Чортківський",
        x: 197.76103,
        y: 299.10066,
        parent_id: "UKR292"
    },
    "119": {
        name: "Вижницький",
        x: 166.55267,
        y: 376.26341,
        parent_id: "UKR288"
    },
    "120": {
        name: "Чернівецький",
        x: 206.91919,
        y: 362.12488,
        parent_id: "UKR288"
    },
    "121": {
        name: "Дністровський",
        x: 260.60462,
        y: 342.45388,
        parent_id: "UKR288"
    },
    "122": {
        name: "Калуський",
        x: 100.29897,
        y: 302.66236,
        parent_id: "UKR289"
    },
    "123": {
        name: "Івано-Франківський",
        x: 143.22607,
        y: 292.50404,
        parent_id: "UKR289"
    },
    "124": {
        name: "Надвірнянський",
        x: 128.15243,
        y: 342.31257,
        parent_id: "UKR289"
    },
    "125": {
        name: "Косівський",
        x: 156.98895,
        y: 352.79858,
        parent_id: "UKR289"
    },
    "126": {
        name: "Верховинський",
        x: 145.84757,
        y: 367.54453,
        parent_id: "UKR289"
    },
    "127": {
        name: "Ужгородський",
        x: 24.61294,
        y: 313.68453,
        parent_id: "UKR293"
    },
    "128": {
        name: "Мукачівський",
        x: 38.6992,
        y: 332.46621,
        parent_id: "UKR293"
    },
    "129": {
        name: "Хустський",
        x: 66.23144,
        y: 347.40618,
        parent_id: "UKR293"
    },
    "130": {
        name: "Берегівський",
        x: 36.99177,
        y: 363.84015,
        parent_id: "UKR293"
    },
    "131": {
        name: "Тячівський",
        x: 89.49511,
        y: 360.85216,
        parent_id: "UKR293"
    },
    "132": {
        name: "Рахівський",
        x: 117.4542,
        y: 376.00556,
        parent_id: "UKR293"
    },
    "133": {
        name: "м. Київ",
        x: 464.75423,
        y: 175.5514,
        parent_id: "UKR4826"
    },
    "134": {
        name: "Коломийський",
        x: 170.75184,
        y: 333.79269,
        parent_id: "UKR289"
    }
}