//
//
//
//
//
//
//
//

import FormBase from "../components/Forms/FormBase.vue";
import RegisterForm from "../components/Forms/RegisterForm.vue";
import modal from "../components/modal.vue";
export default {
    components: { modal, FormBase, RegisterForm },
    name: "LoginPage",
};
